import moment from 'moment-timezone';
import { ProColumns } from '@ant-design/pro-table';
import { Space, Typography } from 'antd';
import React from 'react';
import { IReferralItem } from '../../../../../hooks/users';
import Table from '../../../../Common/Table';
import { REFERAL_DATA } from '../../../../../utils/constantData';
import Status from '../../Referrals/Status';

const ReferralsTable = () => {
  const tableRequest = () => {
    const sortedData = REFERAL_DATA.sort((a, b) => {
      const aTimestamp = a.updatedAt || a.createdAt;
      const bTimestamp = b.updatedAt || b.createdAt;

      return moment(bTimestamp).valueOf() - moment(aTimestamp).valueOf();
    });

    return Promise.resolve({ data: sortedData, success: true, total: sortedData.length });
  };

  const columns: ProColumns<IReferralItem>[] = [
    {
      title: 'Contact',
      sorter: false,
      render: (_, row) => (
        <Space direction="vertical" size={4}>
          <Typography.Text>{row.fullName}</Typography.Text>
          <Typography.Text className="fs-075 color-gray nowrap">{row.email}</Typography.Text>
          <Typography.Text className="fs-075 color-gray nowrap">{row.phoneNumber}</Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'comment',
      sorter: false,
    },
    {
      title: 'Report name / date',
      sorter: false,
      render: (_, row) => (
        <Space direction="vertical" size={4}>
          <Typography.Text>{row.Report?.name}</Typography.Text>
          <Typography.Text className="fs-075 color-gray">
            {moment(row.updatedAt).format('ddd DD MMM, YYYY hh:mm a') ||
              moment(row.createdAt).format('ddd DD MMM, YYYY hh:mm a')}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Lead Status',
      dataIndex: 'status',
      sorter: false,
      render: (_, row) => <Status status={row.status} />,
    },
  ];

  return (
    <Table<IReferralItem>
      search={false}
      columns={columns}
      showSorterTooltip={false}
      toolBarRender={false}
      pagination={false}
      request={tableRequest as any}
    />
  );
};

export default ReferralsTable;
