/* eslint-disable no-param-reassign */
import { FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment-timezone';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { ROWS } from '../components/Pages/Reports/constants';
import {
  IReport,
  IReportLoan,
  IReportSettingRow,
  IReportSettings,
  useReportCreate,
  useReportId,
  useReportLoanCreate,
  useReportLoanDelete,
  useReportLoans,
  useReportLoanUpdate,
  useReportSettingsById,
  useReportSettingsUpdate,
  useReportTrackActivities,
  useReportUpdate,
} from '../hooks/reports';
import { JsonResult } from '../types';
import { ILoanValue } from '../types/reports';
import { prepareLoanDataFromForm } from '../utils/prepareData';
import { toFixed, toFloat } from '../utils/text';
import { fetchIp } from '../utils';

interface ReportsContext {
  form?: FormInstance;
  errors?: string[];
  reportId?: string;
  setReportId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  isBorrower?: boolean;
  loans?: IReportLoan[];
  getLoans?: () => void;
  loansLoading?: boolean;
  report?: IReport;
  getReport?: () => void;
  reportLoading?: boolean;
  settings?: IReportSettings;
  getSettings?: () => void;
  settingsLoading?: boolean;
  handleLoansUpdate?: (event?: unknown, withLoading?: boolean) => void;
  handleReportUpdate?: (status?: string, callback?: () => void) => void;
  handleReportSettingsUpdate?: (type: string) => void;
  setLoansLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue = {
  loansLoading: false,
  reportLoading: false,
};

export const ReportsContext = createContext<ReportsContext>(defaultValue);

interface IReportsProvider extends PropsWithChildren {
  value?: ReportsContext;
}
const ReportsProvider = ({ children, value }: IReportsProvider) => {
  const [form] = useForm();
  const [errors, setErrors] = useState<string[]>();
  const [report, setReport] = useState<IReport>();
  const [loans, setLoans] = useState<IReportLoan[]>();
  const [reportId, setReportId] = useState<string>();
  const [loansLoading, setLoansLoading] = useState<boolean>(false);
  const reportLoans = useReportLoans();
  const reportById = useReportId();
  const reportSettingsById = useReportSettingsById();
  const navigate = useNavigate();
  const reportUpdate = useReportUpdate();
  const reportLoanCreate = useReportLoanCreate();
  const reportLoanUpdate = useReportLoanUpdate();
  const reportLoanDelete = useReportLoanDelete();
  const reportSettingsUpdate = useReportSettingsUpdate();
  const reportCreate = useReportCreate();
  const reportTrackActivities = useReportTrackActivities();

  const handleErrorsSave = (error: JsonResult) => {
    const foundErrors: string[] = [];

    error.errorFields?.forEach((item: JsonResult) => {
      item.errors?.forEach((errorMessage: string) => {
        foundErrors.push(errorMessage);
      });
    });

    setErrors(foundErrors);
  };

  useEffect(() => {
    if (!reportId) return;

    if (!reportSettingsById.data) getSettings();
    if (!report) getReport();
    if (!loans) getLoans();
  }, [reportId]);

  const getReport = () => {
    if (reportId) {
      reportById
        .fetch(undefined, reportId)
        .then((res) => {
          setReport(res?.data);
          form?.setFieldValue(['report', 'name'], res?.data.name);
          form?.setFieldValue(['report', 'closingText'], res?.data.closingText || undefined);
          form?.setFieldValue(['report', 'welcome_message'], res?.data.welcome_message || undefined);
          form?.setFieldValue(['report', 'selectOptions'], res?.data.selectOptions || undefined);
        })
        .catch(() => {
          navigate('/not-found');
        });
    }
  };

  const getSettings = () =>
    reportSettingsById.fetch(undefined, reportId).then((res) => {
      if (!res?.data) return;

      res.data.reportView
        .sort((a, b) => a.order - b.order)
        .forEach((row) => {
          form?.setFieldValue(['reportView', row.name], row);
        });

      ROWS.forEach((row) => {
        const settingsRow = res.data.tableView.find((item) => item.name === row.name);

        form.setFieldValue(['tableView', row.name], {
          ...row,
          ...settingsRow,
        });
      });
    });

  const getLoans = () => {
    reportLoans.fetch({ id: reportId }).then((res) => {
      setLoans(res?.data);
      const newLoans: ILoanValue[] = [];

      res?.data
        .sort((a, b) => toFloat(a.order) - toFloat(b.order))
        .forEach((loan) => {
          const loanData = { ...loan };

          delete loanData.Report;

          const newLoan: ILoanValue = {
            ...loanData,
            startAt: loanData.startAt || moment().format('YYYY-MM-DD HH:mm:ss'),
            prepare: loanData.prepare || 3,
            searchBid: loanData.searchBid || 30,
            close: loanData.close || 21,
            currentInterestRate: loanData.currentInterestRate || loanData.interestRate,
            interestRateWithPoint: loanData.interestRateWithPoint || 0,
            totalLoanPoints: loanData.totalLoanPoints || loanData.loanPoints,
            totalCost: loanData.totalCost || loanData.closingCosts,
            otherDeductions: loanData.otherDeductions ?? 0,
          };

          ROWS.forEach((item) => {
            newLoan[item.name] = {
              value: loan[item.name],
            };
            if (item.subField) {
              newLoan[item.subField.name] = {
                value: loan[item.subField.name],
              };
            }
          });

          newLoans.push(newLoan);
        });
      form?.setFieldValue('loans', newLoans);
    });
  };

  const createReport = () => {
    if (reportId) return;

    form
      .validateFields()
      .then((values) => {
        const { tableView } = values;
        const loanColor: JsonResult = {};

        Object.entries(tableView).forEach(([key, settingsValue]) => {
          const row = settingsValue as JsonResult;

          if (!row?.color) return;

          loanColor[key] = row.color;
        });
        fetchIp().then((data) => {
          reportCreate.fetch({ name: values.report.name, status: 'created', loanColor, ip: data.ip }).then((res) => {
            if (!res?.data.id) return;

            setReportId(res?.data.id);
            const preparedLoans = prepareLoanDataFromForm(values.loans);

            reportTrackActivities.fetch({ id: res.data.id, type: 'created' });

            reportLoanCreate.fetch(preparedLoans, `${res.data.id}`);
          });
        })
          .catch((error) => {
            handleErrorsSave(error);
          });
      })
  };
  // let debounceTimeout: string | number | NodeJS.Timeout | null | undefined = null;

  const handleLoansUpdate = (event?: unknown, withLoading?: boolean) => {
    if (!loans) return;

    if (!reportId) {
      createReport();

      return;
    }

    if (withLoading) {
      setLoansLoading(true);
    }

    const newLoans: ILoanValue[] = form?.getFieldValue('loans').map((loan: ILoanValue, index: number) => 
      ({ ...loan, order: index + 1 }));
    const removedLoans = loans.filter((loan) => !newLoans.some((item) => item.id === loan?.id));
    const updatedLoans = newLoans.filter((loan) => loan?.id);

    let addedLoans = newLoans.filter((loan) => !loan?.id);

    if (value?.isBorrower && addedLoans.length) {
      addedLoans = addedLoans.map((loan) => ({ ...loan, type: 'borrower' }));
      reportLoanCreate.fetch(prepareLoanDataFromForm(addedLoans), reportId).then(() => {
        getLoans();
      });

      setTimeout(() => setLoansLoading(false), 1500);

      return;
    }

    Promise.all([
      removedLoans?.map((loan) => reportLoanDelete.fetch(loan.id)),
      ...(addedLoans.length ? [reportLoanCreate.fetch(prepareLoanDataFromForm(addedLoans), reportId)] : []),
      ...(updatedLoans.length ? [reportLoanUpdate.fetch(prepareLoanDataFromForm(updatedLoans), reportId)] : []),
    ])
      .then((res: any) => {
        if (
          addedLoans.length ||
          removedLoans.length ||
          (updatedLoans.length && addedLoans.length) ||
          (updatedLoans.length && removedLoans.length)
        ) {
          getLoans();
        } else {
          setLoans(res[res.length - 1]?.data);
        }
      })
      .finally(() => {
        setTimeout(() => setLoansLoading(false), 2000);
      });
  };

  const handleReportUpdate = (status?: string, callback?: () => void) => {
    if (!reportId) {
      createReport();

      return;
    }

    form
      .validateFields()
      .then((values) => {
        if (typeof values.report.welcome_message === 'object') {
          const html = draftToHtml(values.report.welcome_message);

          values.report.welcome_message = html;
        }
        reportUpdate
          .fetch(
            {
              ...values.report,
              status: status || reportById.data?.data.status || 'draft',
              selectOptions: values.movingTo,
            },
            reportId
          )
          .then(callback);
      })
      .catch((error) => {
        handleErrorsSave(error);
      });
  };

  const handleReportSettingsUpdate = (type: string) => {
    if (!reportId || !reportSettingsById.data?.data) {
      createReport();

      return;
    }

    form
      .validateFields()
      .then((values) => {
        const newSettings = Object.values(values[type]).filter(
          (section) => (section as IReportSettingRow).id
        ) as IReportSettingRow[];

        if (!newSettings.length) return;

        reportSettingsUpdate.fetch({ [type]: newSettings }, reportId
        );
      })
      .catch((error) => {
        handleErrorsSave(error);
      });
  };

  return (
    <ReportsContext.Provider
      value={{
        ...value,
        form,
        errors,
        reportId,
        setReportId,
        loans,
        getLoans,
        loansLoading,
        report,
        getReport,
        reportLoading: reportById.loading,
        settings: reportSettingsById.data?.data,
        getSettings,
        settingsLoading: reportSettingsById.loading,
        handleLoansUpdate,
        handleReportUpdate,
        handleReportSettingsUpdate,
        setLoansLoading,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.defaultProps = {
  value: undefined,
};

export default ReportsProvider;

export const useContextReports = (): ReportsContext => useContext(ReportsContext);
