import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { DraggableProvided } from 'react-beautiful-dnd';
import { IReportLoan, useReportSavingMonthly } from '../../../../../hooks/reports';
import { IBarChartData } from '../../../../Common/Charts/types';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import { useContextReports } from '../../../../../context/reports';
import MovingTo from '../../../../Common/MovingTo';
import CustomInput from '../../../../Common/CustomInput';
import BarChart from '../../../../Common/Charts/BarChart';
import { commafy } from '../../../../../utils/text';

interface ISavingForIncreasing {
  provided?: DraggableProvided;
}

const SavingForIncreasing = ({ provided }: ISavingForIncreasing) => {
  const name = 'savingForIncreasing';
  const [loanKey, setLoanKey] = useState<number>(0);
  const form = Form.useFormInstance();
  const { loans } = useContextReports();
  const movingTo = useWatch(['movingTo', name], form);
  const calculateSaving = useReportSavingMonthly();
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState<IBarChartData | undefined>(undefined);

  useEffect(() => {
    if (!movingTo || !loans || !isSectionActive) return;

    const index = loans.findIndex((item: IReportLoan) => item.id === movingTo);

    if (index >= 0) {
      setLoanKey(index);
      calculateSaving.fetch({
        id: movingTo,
        montlySavingIncrease: form.getFieldValue(['loans', loanKey, 'montlySavingIncrease']),
      }).then((res) => {
        if (res?.data) {
          const currentYear = new Date().getFullYear();

          const result = res.data.savingArray.map((value, i) => ({
            x: currentYear + i,
            y: parseFloat(value),
          }));

          const chartData: IBarChartData = {
            labels: result.map(item => String(item.x)),
            datasets: [
              {
                label: 'Savings Over Time',
                data: result.map(item => item.y),
                maxBarThickness: 15,
                inflateAmount: 1,
                backgroundColor: "#1e0c68",
                borderRadius: {
                  topLeft: 4,
                  topRight: 4,
                },
              }
            ],
          };

          setData(chartData);
        }
      });
    }

  }, [movingTo, loans, isSectionActive, loanKey]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReportSection
      id={name}
      name={name}
      title="Impact of Increasing Monthly Payment on Mortgage Savings"
      provided={provided}
    >
      <Space direction="vertical" size={20}>
        <Row>
          <Col style={{ paddingTop: '10px' }}>
            <Space size={60}>
              <TextWithLabel
                label="Total Interest Savings Over The Life Of The Loan"
                value={`$${commafy(calculateSaving.data?.data.totalSaving || 0)}`}
              />
              {windowWidth >= 867 && (
                <TextWithLabel
                  label="Loan Payoff Acceleration"
                  value={`${calculateSaving.data?.data.yearsSaving || 0} years,
                 ${calculateSaving.data?.data.monthsSaving || 0} months`}
                />)}
              {windowWidth >= 867 && (
                <CustomInput
                  inputClassName="bg-white"
                  formItemProps={{
                    className: 'm-0 label-gray',
                    label: 'Additional Monthly Payment',
                    name: ['loans', loanKey, 'montlySavingIncrease'],
                  }}
                  identifier={['loans', loanKey, 'montlySavingIncrease']}
                  bordered
                  prefix="$"
                  placeholder="$0"
                />
              )}
              {windowWidth >= 867 && (
                <MovingTo name={name} label='Select Loan Option'/>
              )}
            </Space>
          </Col>
          {windowWidth < 867 && (
            <Col xl={12} span={24}>
              <TextWithLabel
                label="Loan Payoff Acceleration"
                value={`${calculateSaving.data?.data.yearsSaving || 0} years,
                  ${calculateSaving.data?.data.monthsSaving || 0} months`}
              />
            </Col>
          )}
          {windowWidth < 867 && (
            <Col xl={12} span={24}>
              <CustomInput
                inputClassName="bg-white"
                formItemProps={{
                  className: 'm-0 label-gray',
                  label: 'Additional Monthly Payment',
                  name: ['loans', loanKey, 'montlySavingIncrease'],
                }}
                identifier={['loans', loanKey, 'montlySavingIncrease']}
                bordered
                prefix="$"
                placeholder="$0"
              />
            </Col>
          )}
          {windowWidth < 867 && (
            <Col xl={12} span={24}>
              <MovingTo name={name} label='Select Loan Option' />
            </Col>
          )}
        </Row>
        <BarChart mode="nearest" hideLegend data={data} loading={calculateSaving.loading} />
      </Space>
    </ReportSection>
  );
};

SavingForIncreasing.defaultProps = {
  provided: undefined,
};

export default SavingForIncreasing;
