import { Button, Space, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../Common/ContentCard';
import ReferralRow from './Row';
import { REFERAL_DATA } from '../../../../utils/constantData';

const Referrals = () => {
  const navigate = useNavigate();
  const data = REFERAL_DATA;

  const handleViewAll = () => {
    navigate('/home/referrals');
  };

  return (
    <ContentCard bordered padding={20} height="100%">
      <Space direction="vertical" size={20}>
        <div className="flex-row flex-justify-space-between gap-20">
          <Typography.Title level={2} style={{ color: '#464646' }}>
            Recent referrals
          </Typography.Title>
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        </div>
        <div className="position-relative flex-1">
          {data.map((referral, index) => (
            <ReferralRow key={referral.id} data={referral} isLast={index === data.length - 1} />
          ))}
        </div>
      </Space>
    </ContentCard>
  );
};

export default Referrals;
