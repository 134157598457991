import { Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../Common/ContentCard';
import ReportRow from './Row';
import { REPORT_TABLE_DATA } from '../../../../utils/constantData';

const Reports = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<any[]>();

  useEffect(() => {
    setReports(REPORT_TABLE_DATA);
  }, []);

  const handleViewAll = () => {
    navigate('/home/activities');
  };

  return (
    <ContentCard bordered padding={20} height="100%">
      <div className="flex-col gap-20">
        <div className="flex-row flex-justify-space-between gap-20">
          <Typography.Title level={2} style={{ color: '#464646' }}>
            Recent reports
          </Typography.Title>
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        </div>
        <div className="position-relative flex-1">
          <div>
            {reports &&
              reports.map((report, index) => (
                <ReportRow key={report.id} report={report} isLast={index === reports.length - 1} />
              ))}
          </div>
        </div>
      </div>
    </ContentCard>
  );
};

export default Reports;
